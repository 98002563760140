<template>
  <div class="member_I">
    <div class="headNavs">
      <div class="headTilte">会员订单</div>
      <div class="headDetails">显示平台会员充值的订单</div>
    </div>

    <div class="capitalNav">
      <div style="display:flex;justify-content: space-between;">
        <div class="NavLi">
          <div class="NavLiName">站点：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.site_id" placeholder="全部">
              <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name" :value="item.site_id"></el-option>
            </el-select>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiName">昵称：</div>
          <div class="NavLiIpt">
            <el-input v-model="search.nickname" placeholder="请填写昵称" ></el-input>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiName">手机号：</div>
          <div class="NavLiIpt">
            <el-input v-model="search.mobile" placeholder="请填写手机号" ></el-input>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiName">订单号：</div>
          <div class="NavLiIpt">
            <el-input v-model="search.pay_sn" placeholder="请填写订单号"></el-input>
          </div>
        </div>
        
        <div class="NavLi" v-for="obj in 4" :key="obj"></div>
      </div>
      <div style="display:flex;justify-content: space-between;margin-top:20px">
        <div class="NavLi">
          <div class="NavLiName">会员类型：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.pay_level" placeholder="全部">
              <el-option v-for="(item,key) in LevelList" :key="key" :label="item" :value="key"></el-option>
            </el-select>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiName">充值时间</div>
          <div class="NavLiIpt">
            <el-date-picker v-model="search.time.min" type="date" placeholder="选择时间" :clearable="false" ></el-date-picker>
            <span class="timeSpan">至</span>
            <el-date-picker v-model="search.time.max" type="date" placeholder="选择时间" :clearable="false" ></el-date-picker>
          </div>
          <div class="NavLiQuery" style="margin-left: 24px;" @click="BtnSearch()">查询</div>
          <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
        </div>
        <div class="NavLi" v-for="obj in 7" :key="obj"></div>
      </div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入昵称">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelect">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="payment_id" label="ID" width="55" align="left"></el-table-column>
        <el-table-column prop="site.name" label="站点"  align="center"></el-table-column>
        <el-table-column prop="user.nickname" label="昵称"  align="center"></el-table-column>
        <el-table-column prop="user.mobile" label="手机"  align="center"></el-table-column>
        <el-table-column prop="pay_sn" label="充值订单号" align="center"></el-table-column>
        <el-table-column prop="money" label="充值金额"  align="center"></el-table-column>
        <el-table-column prop="pay_level" label="会员类型"  align="center"></el-table-column>
        <el-table-column prop="createtime" label="创建时间" align="center"></el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>


  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "index",
  data() {
    return {
      //搜索参数
      search: {
        site_id: "",
        nickname: "",
        mobile: "",
        pay_sn: "",
        pay_level: "",
        time: {
          min: "",
          max: "",
        },
        keyword: "",
      },
      page: {
        currentPage: 1,
        pageSize: 10,
      },
      total: 0,
    }
  },
  mounted() {
    var that = this
    that.loadTable();

    axios.get('/admin/member/payment/statisticsColumns', {   // 设置选项
    }).then(function (res) {
      if (res.data.code == 0) {
        alert('请求失败');
      } else {
        that.LevelList = res.data.data.level;
      }
    })

    axios.get('/admin/ajax/siteList', {   // 站点列表
    }).then(function (res) {
      if (res.data.code == 0) {
        alert('请求失败');
      } else {
        that.SiteList = res.data.data
      }
    })
  },
  methods: {

    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/member/payment/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        //console.log("数据加载")
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
        //console.log(that.tableData);
      })
    },

    BtnSearch(e) {
      var that = this
      if (that.search.time.min != '') {
        that.search.time.min = that.formatDate(that.search.time.min);
      }
      if (that.search.time.max != '') {
        that.search.time.max = that.formatDate(that.search.time.max);
      }
      that.page.currentPage = 1;
      if (e == false) {
        that.search = {
          site_id: "",
          nickname: "",
          mobile: "",
          pay_sn: "",
          pay_level: "",
          time: {
            min: "",
            max: "",
          },
          keyword: "",
        };
      }
      that.loadTable();
    },
    handleSelect(e) {          // 勾选
      var that = this
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          arr.push(element.id)
          acc.push(element)
        }
        that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;

      return y + "-" + MM + "-" + d
    },
  }
}
</script>

<style scoped>     
.Mem_I /deep/ .el-input__inner{
  height: 32px;
  line-height: 32px;
} 
</style>